export default function getAwardPrecedenceMapper() {
  const awardPrecendence = {
    
    //Usa awards precedence
    "Medal of Honor": ["award-usa-13"],
    "Distinguished Service Cross": ["award-usa-12"],
    "Silver Star": ["award-usa-18"],
    "Legion of Merit": ["award-usa-17"],
    "Distinguished Flying Cross": ["award-usa-06"],
    "Distinguished Flying Cross (First Oak Leaf Cluster)": ["award-usa-07"],
    "Distinguished Flying Cross (Second Oak Leaf Cluster)": ["award-usa-08"],
    "Distinguished Flying Cross (Third Oak Leaf Cluster)": ["award-usa-09"],
    "Distinguished Flying Cross (Fourth Oak Leaf Cluster)": ["award-usa-10"],
    "Distinguished Flying Cross (Fifth Oak Leaf Cluster)": ["award-usa-11"],
    "Purple Heart": ["award-usa-14"],
    "Purple Heart (First Oak Leaf Cluster)": ["award-usa-15"],
    "Purple Heart (Second Oak Leaf Cluster)": ["award-usa-16"],
    "Air Medal": ["award-usa-02"],
    "Air Medal (First Oak Leaf Cluster)": ["award-usa-03"],
    "Air Medal (Second Oak Leaf Cluster)": ["award-usa-04"],
    "Air Medal (Third Oak Leaf Cluster)": ["award-usa-05"],
    "Pilot's Badge": ["award-usa-01"],
    
    //Raf awards precedence
    "Victoria Cross": ["award-raf-07"],
    "Bar to the Distinguished Service Order": ["award-raf-06"],
    "Distinguished Service Order": ["award-raf-05"],
    "Second Bar to the Distinguished Flying Cross": ["award-raf-04"],
    "Bar to the Distinguished Flying Cross": ["award-raf-03"],
    "Distinguished Flying Cross": ["award-raf-02"],
    "Mentioned in Dispatches": ["award-raf-11"],
    "Third Wound Stripe": ["award-raf-10"],
    "Second Wound Stripe": ["award-raf-09"],
    "Wound Stripe": ["award-raf-08"],
    "Pilot's Badge": ["award-raf-01"],
    
    //Ger awards precedence
    "Knight's Cross of the Iron Cross with Oak Leaves, Swords, and Diamonds": ["award-ger-09"],
    "Knight's Cross of the Iron Cross with Oak Leaves and Swords": ["award-ger-08"],
    "Knight's Cross of the Iron Cross with Oak Leaves": ["award-ger-07"],
    "Knight's Cross of the Iron Cross": ["award-ger-06"],
    "German Cross in Gold": ["award-ger-05"],
    "Luftwaffe Honor Roll Clasp": ["award-ger-21"],
    "Iron Cross 1st Class": ["award-ger-03"],
    "Iron Cross 2nd Class": ["award-ger-02"],
    "Wound Badge in Gold": ["award-ger-12"],
    "Wound Badge in Silver": ["award-ger-11"],
    "Wound Badge in Black": ["award-ger-10"],
    "Front Flying Clasp for Attack Planes with Pendant": ["award-ger-25"],    
    "Front Flying Clasp for Fighters with Pendant": ["award-ger-16"],
    "Front Flying Clasp for Attack Planes in Gold": ["award-ger-24"],
    "Front Flying Clasp for Fighters in Gold": ["award-ger-15"],
    "Front Flying Clasp for Attack Planes in Silver": ["award-ger-23"],
    "Front Flying Clasp for Fighters in Silver": ["award-ger-14"],
    "Front Flying Clasp for Attack Planes in Bronze": ["award-ger-22"],
    "Front Flying Clasp for Fighters in Bronze": ["award-ger-13"],
    "Third Wound Stripe": ["award-raf-10"],
    "Second Wound Stripe": ["award-raf-09"],
    "Wound Stripe": ["award-raf-08"],
    "Pilot's Badge": ["award-ger-01"],

    //Sov awards precedence
    "Hero of the Soviet Union (Third Award)": ["award-sov-08"],
    "Hero of the Soviet Union (Second Award)": ["award-sov-08"],
    "Hero of the Soviet Union": ["award-sov-08"],
    "Order of Lenin (Second Award)": ["award-sov-07"],
    "Order of Lenin": ["award-sov-07"],
    "Order of the Red Banner (Third Award)": ["award-sov-04"],
    "Order of the Red Banner (Second Award)": ["award-sov-04"],
    "Order of the Red Banner": ["award-sov-04"],
    "Order of the Red Star (Third Award)": ["award-sov-03"],
    "Order of the Red Star (Second Award)": ["award-sov-03"],
    "Order of the Red Star": ["award-sov-03"],
    "Order of the Patriotic War First Class": ["award-sov-12"],
    "Order of Alexander Nevsky": ["award-sov-09"],
    "Medal for Courage": ["award-sov-02"],
    "Medal for Battle Merit": ["award-sov-01"],
    "Yellow Wound Stripe": ["award-sov-11"],
    "Red Wound Stripe": ["award-sov-10"],

  };
  return awardPrecendence;
}

