import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import Modal from "../Modal"; // Import your CustomModal component
import getAwardPrecedenceMapper from "../../components/getAwardPrecedenceMapper";
import "../../styles/Awards/awards.css";

export default function PilotAwards({ pilotData }) {
  const [allAwards, setAllAwards] = useState(pilotData?.Awards || []);
  const [rankConfig, setRankConfig] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);

  // For loading states on awards
  const [awardDollStates, setAwardDollStates] = useState({});
  const [awardRibbonStates, setAwardRibbonStates] = useState({});

  // For fetching once
  const hasFetchedSorties = useRef(false);
  const personaId = pilotData?.persona_id;

  useEffect(() => {
    // Fetch rank config
    const fetchRankConfig = async () => {
      try {
        const response = await axios.get(
          "https://il2statsapi.combatbox.net/api/rankconfig"
        );
        setRankConfig(response.data);
      } catch (err) {
        console.error("Error fetching rank config:", err);
      }
    };
    fetchRankConfig();
  }, []);

  useEffect(() => {
    // Only fetch once per persona
    if (!personaId || hasFetchedSorties.current) return;

    const fetchSortiesAndMergeAwards = async () => {
      try {
        const response = await axios.get(
          `https://il2statsapi.combatbox.net/api/sorties?persona=${personaId}`
        );
        if (Array.isArray(response.data)) {
          const newAwardsFromSorties = [];
          response.data.forEach((sortie) => {
            if (Array.isArray(sortie.Awards)) {
              sortie.Awards.forEach((awardObj) => {
                newAwardsFromSorties.push({
                  name: awardObj.Name || "",
                  image: awardObj.Image || "",
                  description: awardObj.Description || "",
                  requirements: awardObj.Requirements || "",
                  isSortieAward: true,
                });
              });
            }
          });

          // Merge & deduplicate
          const mergedAndDeduped = deduplicateAwards(allAwards, newAwardsFromSorties);

          // (Optional) If you still want sortie awards first:
          mergedAndDeduped.sort((a, b) => {
            if (a.isSortieAward && !b.isSortieAward) return -1;
            if (!a.isSortieAward && b.isSortieAward) return 1;
            return 0;
          });

          setAllAwards(mergedAndDeduped);
        }
      } catch (error) {
        console.error("Error fetching sorties or merging awards:", error);
      } finally {
        hasFetchedSorties.current = true;
      }
    };

    fetchSortiesAndMergeAwards();
  }, [personaId, allAwards]);

  // Dedup helper
  function deduplicateAwards(existingAwards, newAwards) {
    const map = new Map();
    existingAwards.forEach((aw) => {
      const key = (aw.name || "") + "#" + (aw.image || "");
      map.set(key, aw);
    });
    newAwards.forEach((aw) => {
      const key = (aw.name || "") + "#" + (aw.image || "");
      map.set(key, aw);
    });
    return Array.from(map.values());
  }

  // Precedence compare
  function compareByPrecedence(a, b) {
    const precedenceMap = getAwardPrecedenceMapper() || {};
    const precedenceKeys = Object.keys(precedenceMap);

    const idxA = precedenceKeys.indexOf(a.name);
    const idxB = precedenceKeys.indexOf(b.name);

    // If neither is found, treat them as equal
    if (idxA === -1 && idxB === -1) return 0;
    // If only one is found, that one goes first
    if (idxA === -1) return 1;
    if (idxB === -1) return -1;
    // Otherwise compare by their indices in the array
    return idxA - idxB;
  }

  // If you want the final box to show everything purely by precedence
  // (regardless of whether it was sortieAward or not), you can sort here:
  // e.g., sortedAllAwards is used in the medals box
  const sortedAllAwards = useMemo(() => {
    const clone = [...allAwards];
    // If you STILL want sortieAwards first, then precedence,
    // you'd do that here:
    // clone.sort((a, b) => {
    //   if (a.isSortieAward && !b.isSortieAward) return -1;
    //   if (!a.isSortieAward && b.isSortieAward) return 1;
    //   return compareByPrecedence(a, b);
    // });

    // If you want pure precedence only, no grouping by sortie awards:
    clone.sort(compareByPrecedence);

    return clone;
  }, [allAwards]);

  // Basic country map
  const countryMap = {
    101: "Sov",
    102: "Raf",
    103: "Usa",
    201: "Ger",
  };

  function getPilotRankImagePath(pilotData) {
    if (!pilotData) return "";
    const folderName = countryMap[pilotData.country];
    // Remove extension from RankImage
    const sanitizedRankImage = pilotData.RankImage.replace(/\.[^.]+$/, "");
    return `${process.env.PUBLIC_URL}/images/pilot-ranks/${folderName}/${sanitizedRankImage}-doll.png`;
  }

  function getPilotAwardImagePath(pilotData, awardImage, suffix) {
    if (!pilotData || !pilotData.PilotPortrait) return "";
    const folderName = countryMap[pilotData.country];
    if (suffix === "doll") {
      return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}-doll.png`;
    }
    if (suffix === "ribbon") {
      return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}-ribbon.png`;
    }
    return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}.webp`;
  }

  // Build RIBBON IMAGES (sorted by precedence) for the overlay
  const ribbonImages = useMemo(() => {
    // If Sov or Ger, no ribbons
    if (pilotData.country === 101 || pilotData.country === 201) {
      return [];
    }
    // Filter out awards you don't show as ribbons
    const filtered = sortedAllAwards.filter((award) => {
      return (
        award.name !== "Pilot's Badge" &&
        award.name !== "Mentioned in Dispatches" &&
        award.name !== "Wound Stripe" &&
        award.name !== "Second Wound Stripe" &&
        award.name !== "Third Wound Stripe"
      );
    });

    // Now build <img> for each
    return filtered.map((award, index) => {
      const src = getPilotAwardImagePath(pilotData, award.image, "ribbon");
      return (
        <img
          key={index}
          src={src}
          alt={award.name}
          className="awards-ribbon"
          onError={() =>
            setAwardRibbonStates((prev) => ({ ...prev, [index]: false }))
          }
        />
      );
    });
  }, [sortedAllAwards, pilotData, awardRibbonStates]);

  // Then chunk them as you wish
  const specialChunks = useMemo(() => chunkRemainderFirst(ribbonImages, 4), [
    ribbonImages,
  ]);

  // Helper for chunking with "remainder first"
  function chunkRemainderFirst(images, size = 4) {
    if (!images) return [];
    if (images.length <= size) {
      return [images];
    }
    const remainderCount = images.length - size;
    const remainderChunk = images.slice(0, remainderCount);
    const mainChunk = images.slice(remainderCount);
    return [remainderChunk, mainChunk];
  }

  // Build "paper-doll" overlay images
  const validDollImages = sortedAllAwards
    .map((award, index) => {
      if (awardDollStates[index] === false) return null;
      const src = getPilotAwardImagePath(pilotData, award.image, "doll");
      return (
        <img
          key={index}
          src={src}
          alt={award.name}
          className="paper-doll"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 3 + index,
          }}
          onError={() =>
            setAwardDollStates((prev) => ({ ...prev, [index]: false }))
          }
        />
      );
    })
    .filter(Boolean);

  // For the medals box, we also want them in sorted order:
  // sortedAllAwards is already sorted by precedence.
  // We'll map over that below.

  return (
    <div className="pt-5 border bg-USSR">
      <div className="container-fluid">
        <div className="text-start">
          <h3>UNIFORM</h3>
        </div>

        {/* OVERLAY DISPLAY SECTION */}
        <div className="text-center border" style={{ position: "relative" }}>
          {/* PAPER DOLL BACKGROUND */}
          <img
            src={`${process.env.PUBLIC_URL}/images/pilot-awards/paper-dolls/${countryMap[
              pilotData.country
            ]?.toLowerCase()}-paperdoll.png`}
            alt="Paperdoll"
            className="paper-doll"
          />

          {/* RANK OVERLAY */}
          <img
            src={getPilotRankImagePath(pilotData)}
            className="paper-doll"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2,
            }}
          />

          {/* AWARDS (DOLL IMAGES) */}
          {validDollImages}

          {/* RIBBONS */}
          {specialChunks.map((chunk, i) => {
            // If only 1 chunk, it's div-1
            // If 2, the first chunk is div-2, the second chunk is div-1
            let classSuffix = 1;
            if (specialChunks.length === 2) {
              classSuffix = i === 0 ? 2 : 1;
            }
            return (
              <div key={i} className={`col-12 awards-ribbon-div-${classSuffix}`}>
                {chunk}
              </div>
            );
          })}
        </div>

        {/* MEDALS BOX SECTION */}
        <div className="text-start mt-5">
          <h3 className="text-uppercase">Medals Box</h3>
        </div>
        <div className="text-center border awards-box mb-5">
          <div className="d-flex flex-wrap justify-content-center">
            {sortedAllAwards.map((award, index) => {
              const src = getPilotAwardImagePath(pilotData, award.image, false);
              const isMentionedInDispatches =
                award.name === "Mentioned in Dispatches";

              return (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    flex: "0 0 25%", // 4 items per row
                    maxWidth: "25%",
                  }}
                >
                  <img
                    src={src}
                    alt={award.name}
                    title={award.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedAward(award)}
                  />

                  {isMentionedInDispatches && (
                    <div className="awards-dispatches-medals-box">
                      {pilotData.PilotName}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* MODAL FOR AWARD DETAILS */}
      {selectedAward && (
        <Modal
          show={!!selectedAward}
          onHide={() => setSelectedAward(null)}
          title={selectedAward.name}
          body={
            <div>
              <div style={{ position: "relative" }}>
                <img
                  src={getPilotAwardImagePath(
                    pilotData,
                    selectedAward.image,
                    false
                  )}
                  alt={selectedAward.name}
                  style={{ height: "100%", width: "100%", display: "block" }}
                />
                {selectedAward.name === "Mentioned in Dispatches" && (
                  <div className="awards-dispatches-modal">
                    {pilotData.PilotName}
                  </div>
                )}
              </div>
              <p>{selectedAward.description}</p>
              <p>
                <strong>Requirements:</strong> {selectedAward.requirements}
              </p>
            </div>
          }
          size="lg"
        />
      )}
    </div>
  );
}

